<template>
  <div class="complete_box">
    <div class="ls">
      <div class="time">
        <span class="start">开始时间：<b>{{ firstTime }}</b></span>
        <span class="end">截止时间：<b>{{ lastTime }}</b></span>
      </div>
      <div class="chart_box">
        <div class="echart2" id="echart2"></div>
        <el-table
            :data="tableData"
            height="328"
            border
            style="width: 100%"
            show-scrollbar
            key="table2"
        >
          <el-table-column
              prop="date"
              label="时间"
              width="150">
          </el-table-column>
          <el-table-column
              prop="num"
              label="数量"
              width="300"
              class-name="table_num"
          >
          </el-table-column>
          <el-table-column
              prop="address"
              label="百分比"
              class-name="table_address"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "complete",
  data() {
    return {
      data : {
        'time': ['2019-05-21', '2019-06-02', '2019-06-03', '2019-06-04', '2019-06-05', '2019-06-06', '2019-06-07', '2019-06-08', '2019-06-09', '2019-06-10', '2019-06-11', '2019-06-12', '2019-06-13', '2019-06-14', '2019-06-15', '2019-06-16', '2019-06-17', '2019-06-18', '2019-06-19', '2019-06-20', '2019-06-21', '2019-06-22', '2019-06-23', '2019-06-24', '2019-06-25', '2019-06-26', '2019-06-27', '2019-06-28', '2019-06-29', '2019-07-30'],
        'val': [5, 10, 15, 20, 23, 26, 23, 20, 15, 10, 5, 10, 15, 20, 23, 26, 23, 20, 15, 10, 5, 10, 15, 20, 23, 26, 23, 20, 15, 10]
      },
      tableData: [{
        date: '2016-05-03',
        num: 45,
        address: '12%'
      }, {
        date: '2016-05-02',
        num: 45,
        address: '12%'
      }, {
        date: '2016-05-04',
        num: 45,
        address: '12%'
      }, {
        date: '2016-05-01',
        num: 45,
        address: '12%'
      }, {
        date: '2016-05-08',
        num: 45,
        address: '12%'
      }, {
        date: '2016-05-06',
        num: 45,
        address: '12%'
      }, {
        date: '2016-05-07',
        num: 45,
        address: '12%'
      },{
        date: '2016-05-07',
        num: 45,
        address: '12%'
      },{
        date: '2016-05-07',
        num: 45,
        address: '12%'
      },{
        date: '2016-05-07',
        num: 45,
        address: '12%'
      },{
        date: '2016-05-07',
        num: 45,
        address: '12%'
      }
      ]
    }
  },
  mounted() {
    this.amountLine('#04cd83','rgba(4,205,131,0.3)','rgba(4,205,131,0)',this.data);
  },
  computed: {
    firstTime() {
      return this.data.time.length > 0 ? this.data.time[0] : null; // 获取第一个时间，如果数组非空
    },
    lastTime() {
      return this.data.time.length > 0 ? this.data.time[this.data.time.length - 1] : null; // 获取最后一个时间，如果数组非空
    }
  },
  methods:{
    amountLine(color, color1, color2, data) {
      const myChart = this.$echarts.init(
          document.getElementById('echart2')
      )
      myChart.setOption({
        grid: {
          left: 50,
          right: 50,
          bottom: 65,
          top: 38,
          containLabel: true
        },
        dataZoom: [{
          type: 'slider',
          show: true,
          backgroundColor: '#F1F4F7',
          fillerColor: 'rgba(4,205,131,0.3)',
          borderColor: 'none',
          handleSize: 18,
          handleIcon: '@/assets/statistics/handle_icon.png',
          handleColor:'#04cd83',
          showDetail: false,
          showDataShadow: false,
          filterMode: 'none',
          borderRadius: 4,
          start: 0,
          end: 60,
          zoomLock: true,
          brushSelect: false,
          height: 10,
          bottom: 0,
        }, {
          type: 'inside',
          filterMode: 'none',
          zoomOnMouseWheel: false,
          moveOnMouseMove: true,
          moveOnMouseWheel: true
        }],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#98a0a6',
              fontSize: 12,
              padding: [0, 22, 0, 22],
            },
            margin: 13
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#eeeff2',
              width: 2,
              type: 'solid'
            }
          },
          splitLine: {
            show: false
          },
          data: data.time
        },
        yAxis: {
          type: 'value',
          splitNumber: 6,
          axisTick: {
            show: false
          },
          axisLabel: {
            inside: true,
            align: 'top',
            textStyle: {
              color: '#98a0a6',
              fontSize: 12,
              verticalAlign: 'bottom',
              padding: [0, 0, 8, 0]
            },
            margin: 7
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#eeeff2',
              width: 1,
              type: 'dashed'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          axisPointer: {
            lineStyle: {
              color: color1
            }
          },
          formatter: function(params) {
            var result = '<div style="min-width:40px;padding:11px 12px 14px;line-height:1;border-radius:2px;background-color:rgba(0,0,0,.5);"><p style="margin-bottom:10px;font-size:12px;color:#fff;">' + params[0].name + '</p>';
            params.forEach(function(item) {
              if(item.data) {
                result += '<p style="font-size:12px;color:#fff"> 数量：' + '<span style="font-weight:bold;">' + item.data + '</span><p/>';
              }
            });
            return result + '</div>';
          },
          backgroundColor: 'transparent',
          padding: 0
        },
        series: {
          type: 'line',
          showSymbol: false,
          symbol: 'circle',
          symbolSize: 2,
          smooth: true,
          itemStyle: {
            normal: {
              color: color
            }
          },
          lineStyle: {
            color: color,
            width: 2
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: color1
              }, {
                offset: 1,
                color: color2
              }],
              globalCoord: false
            }
          },
          data: data.val
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.complete_box {
  background: #fff;
  min-height: 616px;
}

//去掉空白占位
::v-deep .el-table th.gutter {
  //display: none;
  width: 17px;
  background-color: #F6F7F9;
}
::v-deep .el-table colgroup col[name='gutter'] {
  //display: none;
  width: 17px;
  background-color: #F6F7F9;
}


.el-table::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

.el-table::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  box-shadow: 0px 1px 3px #fff inset;
  border-radius: 10px;
  background-color: #fff;
}


.el-table::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  box-shadow: 0px 1px 3px #CEDAE0 inset;
  border-radius: 10px;
  background-color: #CEDAE0;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}
::v-deep .el-table--border .el-table__cell:first-child .cell{
  padding-left: 29px;
}
::v-deep .is-leaf{
  background-color: #F6F7F9 !important;
  color: #6C7880;
}
::v-deep .is-leaf.table_address{
  .cell{
    color: #6C7880;
  }
}
::v-deep .table_num{
  text-align: center;
}
::v-deep .table_address{
  .cell{
    color: #3984F5;
  }
}
::v-deep .el-table--border .el-table__cell{
  border-right: none;
}
::v-deep .el-table .el-table__cell{
  padding: 8.5px 0;
}

.tab {
  height: 48px;
  border-bottom: 1px solid #eeeff2;
  margin-bottom: 18px;
  li {
    float: left;
    width: 138px;
    text-align: center;
    border-right: 1px solid #eeeff2;
    line-height: 48px;
    font-size: 14px;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    a {
      display: block;
      color: #98a0a6;
    }
    &.on {
      border-bottom-color: #fff;
      font-weight: bold;
      a {
        color: #1190f3;
      }
    }
  }
}
.ls {
  padding-top: 20px;
  .time {
    height: 36px;
    line-height: 36px;
    color: #242d33;
    padding: 0 22px;
    margin-bottom: 30px;
    span {
      position: relative;
      float: left;
      padding-right: 26px;
      margin-right: 26px;
      &:after {
        position: absolute;
        content: '';
        height: 12px;
        top: 12px;
        right: 0;
        border-right: 1px solid #eeeff2;
      }
      &:last-child {
        &:after {
          border-right: none;
        }
      }
    }
  }
  .chart_box{
    display: flex;
    justify-content: space-between;
    /*.tab_table{
      flex: 1;
      width: 496px;
      height: 329px;
    }*/
  }
  .echart1,
  .echart2 {
    height: 349px;
    margin-bottom: 40px;
    width: 1077px;
  }
}
.base_info {
  padding: 0 22px;
  li {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dotted rgba(17, 144, 243, .2);
  }
  h3 {
    line-height: 46px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  .tt {
    line-height: 32px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    span {
      color: #b8c1c9;
      font-weight: normal;
    }
  }
  .more {
    display: inline-block;
    line-height: 30px;
    height: 30px;
    border: 1px solid #1190f3;
    padding: 0 13px;
    border-radius: 2px;
    color: #1190f3;
  }
  .perWay {
    margin-top: 20px;
  }
  .main_table {
    padding: 0;
  }
  .statu {
    position: relative;
    height: 8px;
    padding-right: 12%;
    .bar {
      height: 8px;
      margin-top: 3px;
      border-radius: 4px;
      background: #f6f7f9;
      p {
        border-radius: 4px;
        background: #1190f3;
        height: 8px;
      }
    }
    span {
      position: absolute;
      line-height: 14px;
      top: -3px;
      right: 0;
      width: 10%;
      text-align: left;
    }
  }
}
</style>
<template>
  <div class="sta_box">
    <div class="analysis_source">
      <div class="info">
        <h3>{{ researchInfo.name }}<em class="blue">统计报告</em></h3>
        <p class="other">
          <span>问卷编号：{{ researchInfo.id }}</span>
          <span>收到数据：<b class="blue">{{ researchInfo.getData }}</b> 份</span>
          <span>创建报表：{{ researchInfo.cTime }}</span>
          <span>报表更新：{{ researchInfo.Utime }}</span>
        </p>
        <p class="tag">
          <router-link to="/preview" class="blue" target="_blank">查看问卷</router-link>
          <router-link to="/sheet" class="purple">查看答卷</router-link>
<!--          <a href="javaScript:;" class="green">下载答卷数据</a>-->
        </p>
      </div>
      <div class="tab_box">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="统计报告" name="first">
            <statistical></statistical>
          </el-tab-pane>
          <el-tab-pane label="来源分析" name="second">
            <analysis></analysis>
          </el-tab-pane>
          <el-tab-pane label="完成率分析" name="third">
            <complete></complete>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import statistical from './components/statistical.vue'
import analysis from './components/analysis.vue'
import complete from './components/complete.vue'
export default {
  name: "report",
  components: {
    statistical,
    analysis,
    complete
  },
  data(){
    return {
      activeName: 'first', //切换统计报告。来源分析、完成率分析
      researchInfo:{
        name:'2020年二季度园区发展调研',
        id:82289010,
        getData:1036,
        cTime:'2020年6月23日',
        Utime:'2020年6月24日'
      }
    }
  },
  mounted() {
    this.activeName = this.$route.query.activeName
  },
  methods:{
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped lang="scss">
.sta_box{
  width: 100%;
}
::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav{
  border: none;
  border-right: 1px solid #E4E7ED;
  border-radius: 0 0 0 0;
  .el-tabs__item{
    height: 50px;
    line-height: 50px;
    padding: 0 42px;
  }
}
.analysis_source {
  padding-top: 18px;
  margin: 0 18px;
  .info {
    padding: 18px 22px 20px;
    border: 1px solid #eeeff2;
    background: #fff;
    margin-bottom: 18px;
    h3 {
      line-height: 30px;
      color: #242d33;
      font-size: 18px;
      font-weight: normal;
      em {
        display: inline-block;
        border-radius: 2px;
        line-height: 20px;
        height: 20px;
        border: 1px solid #eeeff2;
        padding: 0 5px;
        color: #eeeff2;
        margin: -4px 0 0 12px;
        font-style: normal;
        font-size: 12px;
        vertical-align: middle;
        &.blue {
          color: #1190f3;
          border-color: #1190f3;
        }
      }
    }
    .other {
      line-height: 42px;
      height: 42px;
      margin-bottom: 4px;
      color: #60696f;
      span {
        float: left;
        margin-right: 48px;
      }
      b {
        font-weight: normal;
      }
      .blue {
        color: #1190f3;
      }
    }
    .tag {
      height: 34px;
      a {
        float: left;
        line-height: 34px;
        margin-right: 10px;
        border-radius: 2px;
        color: #fff;
        padding: 0 16px;
        background: #eeeff2;
        transition: all .3s ease;
        &.blue {
          background: #1190f3;
          &:hover {
            background: #0073cc;
          }
        }
        &.purple {
          background: #7199ff;
          &:hover {
            background: #5585ff;
          }
        }
        &.green {
          background: #04cd83;
          &:hover {
            background: #05c07b;
          }
        }
      }
    }
  }
  .tab_box {
    border: 1px solid #eeeff2;
    background: #fff;
  }
  .tab {
    height: 48px;
    border-bottom: 1px solid #eeeff2;
    margin-bottom: 18px;
    li {
      float: left;
      width: 138px;
      text-align: center;
      border-right: 1px solid #eeeff2;
      line-height: 48px;
      font-size: 14px;
      cursor: pointer;
      border-bottom: 1px solid transparent;
      a {
        display: block;
        color: #98a0a6;
      }
      &.on {
        border-bottom-color: #fff;
        font-weight: bold;
        a {
          color: #1190f3;
        }
      }
    }
  }
  .ls {
    padding-bottom: 30px;
    .time {
      height: 36px;
      line-height: 36px;
      color: #242d33;
      padding: 0 22px;
      span {
        position: relative;
        float: left;
        padding-right: 26px;
        margin-right: 26px;
        &:after {
          position: absolute;
          content: '';
          height: 12px;
          top: 12px;
          right: 0;
          border-right: 1px solid #eeeff2;
        }
        &:last-child {
          &:after {
            border-right: none;
          }
        }
      }
    }
    .echart1,
    .echart2 {
      height: 372px;
      margin-bottom: 40px;
    }
    .main_table {
      padding: 0 22px;
    }
  }
}
</style>